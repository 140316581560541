import { defineStore } from 'pinia';

export const useHelperStore = defineStore('helperStore', {
    state() {
        return {
            error404: false as boolean,
            newCallContactId: null as number | null,
        };
    },
    getters: {
        isError404(): boolean {
            return this.error404;
        }
    },
    actions: {
        change404State(state: boolean) {
            this.error404 = state;
        }
    }
});